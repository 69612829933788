import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { nextTick } from 'vue';
import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { useRoutesStore } from '@/store/modules/routes';
import { isExternal } from '@/utils/validate';
import { openFirstMenu } from '@/config';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: ''
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var router = useRouter();
    var routesStore = useRoutesStore();
    var _b = storeToRefs(routesStore),
      tab = _b.getTab,
      tabMenu = _b.getTabMenu,
      routes = _b.getRoutes;
    var handleTabClick = function () {
      nextTick(function () {
        if (isExternal(tabMenu.value.path)) {
          window.open(tabMenu.value.path);
          setTimeout(function () {
            router.push('/');
          }, 1000);
        } else if (openFirstMenu) router.push(tabMenu.value.redirect || tabMenu.value);
      });
    };
    var __returned__ = {
      router: router,
      routesStore: routesStore,
      tab: tab,
      tabMenu: tabMenu,
      routes: routes,
      handleTabClick: handleTabClick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});