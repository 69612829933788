import { useFullscreen } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var _b = useFullscreen(),
      isFullscreen = _b.isFullscreen,
      toggle = _b.toggle;
    var settingsStore = useSettingsStore();
    var theme = storeToRefs(settingsStore).theme;
    var __returned__ = {
      isFullscreen: isFullscreen,
      toggle: toggle,
      settingsStore: settingsStore,
      theme: theme
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});