import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { defineComponent as _defineComponent } from 'vue';
import { useRoutesStore } from '@/store/modules/routes';
import { handleMatched } from '@/utils/routes';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var route = useRoute();
    var routesStore = useRoutesStore();
    var routes = storeToRefs(routesStore).getRoutes;
    var breadcrumbList = computed(function () {
      return handleMatched(routes.value, route.path).filter(function (item) {
        return !item.meta.breadcrumbHidden;
      });
    });
    var handleTo = function (path) {
      if (path === void 0) {
        path = '';
      }
      return {
        path: path
      };
    };
    var __returned__ = {
      route: route,
      routesStore: routesStore,
      routes: routes,
      breadcrumbList: breadcrumbList,
      handleTo: handleTo
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});