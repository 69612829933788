import request from '@/utils/request'

/**
 * 获取验证码
 * @returns {never}
 */
export function getCaptcha() {
  return request({
    url: '/api/admin/auth/captcha',
    method: 'get',
  })
}

/**
 * 获取上传配置
 */
export const getAliYunOSSToken = () => {
  return request({
    url: '/api/admin/oss/getStsToken',
    method: 'get',
  })
}

/**
 * 获取本地地址
 * @returns
 */
export const getLocalUrl = () => {
  return request({
    url: '/api/admin/auth/localUrl',
    method: 'get',
  })
}

/**
 * 判断是否可访问
 * @returns
 */
export const checkLocal = () => {
  return request({
    url: '/api/admin/auth/isLocal',
    method: 'get',
  })
}

export const uploadFileAction = 'getSessionToken'
