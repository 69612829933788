import { storeToRefs } from 'pinia';
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var settingsStore = useSettingsStore();
    var _b = storeToRefs(settingsStore),
      theme = _b.theme,
      logo = _b.logo,
      title = _b.title;
    var __returned__ = {
      settingsStore: settingsStore,
      theme: theme,
      logo: logo,
      title: title
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});