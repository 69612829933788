import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/personnel',
    name: 'Personnel',
    component: Layout,
    meta: {
      title: '人员管理',
      icon: 'article-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'index',
        name: 'PersonnelIndex',
        component: () => import('@/views/personnel/index.vue'),
        meta: {
          title: '人员档案',
          access: true,
        },
      },
    ],
  },
]
