import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  var _component_vab_logo = _resolveComponent("vab-logo");
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  var _component_el_divider = _resolveComponent("el-divider");
  var _component_vab_menu = _resolveComponent("vab-menu");
  var _component_el_menu = _resolveComponent("el-menu");
  var _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["vab-column-bar-container", (_a = {
      'is-collapse': $setup.collapse
    }, _a['vab-column-bar-container-' + $setup.theme.columnStyle] = true, _a)])
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_vab_logo), _createVNode(_component_el_tabs, {
        modelValue: $setup.tab.data,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.tab.data = $event;
        }),
        "tab-position": "left",
        onTabClick: $setup.handleTabClick
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, function (item, index) {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              key: index + item.name,
              name: item.name
            }, {
              label: _withCtx(function () {
                var _a;
                return [_createElementVNode("div", {
                  class: _normalizeClass(["vab-column-grid", (_a = {}, _a['vab-column-grid-' + $setup.theme.columnStyle] = true, _a)]),
                  title: item.meta.title
                }, [_createElementVNode("div", null, [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
                  key: 0,
                  icon: item.meta.icon,
                  "is-custom-svg": item.meta.isCustomSvg
                }, null, 8 /* PROPS */, ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(item.meta.title), 1 /* TEXT */)])], 10 /* CLASS, PROPS */, _hoisted_1)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_el_menu, {
        "background-color": $setup.variables['column-second-menu-background'],
        "default-active": $setup.activeMenu.data,
        "default-openeds": $setup.defaultOpeneds,
        mode: "vertical",
        "unique-opened": $setup.uniqueOpened
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_divider, null, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.tabMenu ? $setup.tabMenu.meta.title : ''), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.partialRoutes, function (item) {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: item.path
            }, [!item.meta.hidden ? (_openBlock(), _createBlock(_component_vab_menu, {
              key: 0,
              item: item
            }, null, 8 /* PROPS */, ["item"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["background-color", "default-active", "default-openeds", "unique-opened"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}