import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { defineComponent as _defineComponent } from 'vue';
import { useRoutesStore } from '@/store/modules/routes';
import { useSettingsStore } from '@/store/modules/settings';
import { handleActivePath } from '@/utils/routes';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var route = useRoute();
    var routesStore = useRoutesStore();
    var settingStore = useSettingsStore();
    var _b = storeToRefs(routesStore),
      tab = _b.tab,
      activeMenu = _b.activeMenu;
    var fullscreen = storeToRefs(settingStore).fullscreen;
    watch(route, function () {
      if (tab.value.data !== route.matched[0].name) tab.value.data = route.matched[0].name;
      activeMenu.value.data = handleActivePath(route);
    }, {
      immediate: true
    });
    var __returned__ = {
      route: route,
      routesStore: routesStore,
      settingStore: settingStore,
      tab: tab,
      activeMenu: activeMenu,
      fullscreen: fullscreen
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});