import { useUserStore } from '@/store/modules/user'
import { accessControl } from '@/config'
import { isEmpty } from '@/utils/tools'

export function hasAccess(target: any) {
  if (!accessControl) {
    return true
  }
  const { access } = useUserStore()
  let hasRole = false
  if (typeof target === 'string') {
    hasRole = access.includes(target)
  } else {
    if (isEmpty(target.meta) || !target.meta.access) {
      hasRole = true
    } else {
      hasRole = access.includes(target.name)
    }
  }
  return hasRole
}
