import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 2,
  class: "vab-dot vab-dot-error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_tag = _resolveComponent("el-tag");
  var _component_el_menu_item = _resolveComponent("el-menu-item");
  return _openBlock(), _createBlock(_component_el_menu_item, {
    index: $props.itemOrMenu.path,
    onClick: $setup.handleLink
  }, {
    default: _withCtx(function () {
      return [$props.itemOrMenu.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
        key: 0,
        icon: $props.itemOrMenu.meta.icon,
        "is-custom-svg": $props.itemOrMenu.meta.isCustomSvg,
        title: $props.itemOrMenu.meta.title
      }, null, 8 /* PROPS */, ["icon", "is-custom-svg", "title"])) : _createCommentVNode("v-if", true), _createElementVNode("span", {
        title: $props.itemOrMenu.meta.title
      }, _toDisplayString($props.itemOrMenu.meta.title), 9 /* TEXT, PROPS */, _hoisted_1), $props.itemOrMenu.meta.badge ? (_openBlock(), _createBlock(_component_el_tag, {
        key: 1,
        effect: "dark",
        type: "danger"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($props.itemOrMenu.meta.badge), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $props.itemOrMenu.meta.dot ? (_openBlock(), _createElementBlock("span", _hoisted_2, _cache[0] || (_cache[0] = [_createElementVNode("span", null, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["index"]);
}