/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import { useUserStore } from '@/store/modules/user'
import { useRoutesStore } from '@/store/modules/routes'
import { useSettingsStore } from '@/store/modules/settings'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'
import { isEmpty } from '@/utils/tools'
import { routesWhiteList } from '@/config'
import { Router } from 'vue-router'

export function setupPermissions(router: Router) {
  VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
  })
  router.beforeEach(
    async (to: { path: string; query: any }, from: any, next: any) => {
      const {
        getTheme: { showProgressBar },
        setFullscreen,
        updateLocalInfo,
      } = useSettingsStore()
      const { routes, setRoutes } = useRoutesStore()
      const { token, getUserInfo, resetAll } = useUserStore()
      if (!isEmpty(to.query.fullscreen)) {
        setFullscreen(true)
      }

      if (showProgressBar) VabProgress.start()

      let hasToken = token

      if (hasToken) {
        if (routes.length) {
          // 禁止已登录用户返回登录页
          if (to.path === '/login') {
            next({ path: '/' })
            if (showProgressBar) VabProgress.done()
          } else next()
        } else {
          try {
            await getUserInfo()
            await updateLocalInfo()
            await setRoutes()
            next({ ...to, replace: true })
          } catch (err) {
            console.error('vue-admin-beautiful错误拦截:', err)
            await resetAll()
            next(toLoginRoute(to.path))
          }
        }
      } else {
        if (routesWhiteList.includes(to.path)) {
          next()
        } else next(toLoginRoute(to.path))
      }
    }
  )
  router.afterEach((to: any) => {
    document.title = getPageTitle(to.meta.title)
    if (VabProgress.status) VabProgress.done()
  })
}
