import { defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default defineComponent({
  name: 'CustomLayouts',
  setup() {
    const settingsStore = useSettingsStore();
    const {
      updateTheme
    } = settingsStore;
    updateTheme();
    return {};
  }
});