import { storeToRefs } from 'pinia';
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var settingsStore = useSettingsStore();
    var collapse = storeToRefs(settingsStore).collapse;
    var toggleCollapse = settingsStore.toggleCollapse;
    var __returned__ = {
      settingsStore: settingsStore,
      collapse: collapse,
      toggleCollapse: toggleCollapse
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});