import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "vab-tabs"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  var _component_vab_fold = _resolveComponent("vab-fold");
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.layout === 'common' ? (_openBlock(), _createBlock(_component_vab_fold, {
    key: 0
  })) : _createCommentVNode("v-if", true), _createVNode(_component_el_tabs, {
    modelValue: $setup.tabActive,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.tabActive = $event;
    }),
    class: _normalizeClass(["vab-tabs-content", (_a = {}, _a['vab-tabs-content-' + $setup.theme.tabsBarStyle] = true, _a)]),
    type: "card",
    onTabClick: $setup.handleTabClick,
    onTabRemove: $setup.handleTabRemove
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.visitedRoutes, function (item) {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          key: item.path,
          closable: !$setup.isNoCLosable(item),
          name: item.path
        }, {
          label: _withCtx(function () {
            return [_createElementVNode("span", {
              style: {
                "display": "inline-block"
              },
              onContextmenu: _withModifiers($setup.openMenu, ["prevent"])
            }, [$setup.theme.showTabsIcon ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [item.meta && item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
              key: 0,
              icon: item.meta.icon,
              "is-custom-svg": item.meta.isCustomSvg
            }, null, 8 /* PROPS */, ["icon", "is-custom-svg"])) : (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [_createCommentVNode("  如果没有图标那么取第二级的图标 "), _createVNode(_component_vab_icon, {
              icon: item.parentIcon
            }, null, 8 /* PROPS */, ["icon"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), item.meta && item.meta.title ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.meta.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 32 /* NEED_HYDRATION */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["closable", "name"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "class"]), _createVNode(_component_el_dropdown, {
    placement: "bottom-end",
    "popper-class": "vab-tabs-more-dropdown",
    onCommand: $setup.handleCommand,
    onVisibleChange: $setup.handleVisibleChange
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, {
        class: "tabs-more"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_item, {
            command: "refreshThisTab"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "refresh-line"
              }), _cache[2] || (_cache[2] = _createElementVNode("span", null, "刷新", -1 /* HOISTED */))];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "closeOthersTabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "close-line"
              }), _cache[3] || (_cache[3] = _createElementVNode("span", null, "关闭其他", -1 /* HOISTED */))];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "closeLeftTabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "arrow-left-line"
              }), _cache[4] || (_cache[4] = _createElementVNode("span", null, "关闭左侧", -1 /* HOISTED */))];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "closeRightTabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "arrow-right-line"
              }), _cache[5] || (_cache[5] = _createElementVNode("span", null, "关闭右侧", -1 /* HOISTED */))];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "closeAllTabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "close-line"
              }), _cache[6] || (_cache[6] = _createElementVNode("span", null, "关闭全部", -1 /* HOISTED */))];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("span", {
        class: _normalizeClass(["vab-tabs-more", {
          'vab-tabs-more-active': $setup.active
        }])
      }, _cache[1] || (_cache[1] = [_createElementVNode("span", {
        class: "vab-tabs-more-icon"
      }, [_createElementVNode("i", {
        class: "box box-t"
      }), _createElementVNode("i", {
        class: "box box-b"
      })], -1 /* HOISTED */)]), 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  }), $setup.visible ? (_openBlock(), _createElementBlock("ul", {
    key: 1,
    class: "contextmenu el-dropdown-menu",
    style: _normalizeStyle({
      left: $setup.left + 'px',
      top: $setup.top + 'px'
    })
  }, [_createElementVNode("li", {
    class: "el-dropdown-menu__item",
    onClick: $setup.refreshThisTab
  }, [_createVNode(_component_vab_icon, {
    icon: "refresh-line"
  }), _cache[7] || (_cache[7] = _createElementVNode("span", null, "刷新", -1 /* HOISTED */))]), _createElementVNode("li", {
    class: _normalizeClass(["el-dropdown-menu__item", {
      'is-disabled': $setup.visitedRoutes.length === 1
    }]),
    onClick: $setup.closeOthersTabs
  }, [_createVNode(_component_vab_icon, {
    icon: "close-line"
  }), _cache[8] || (_cache[8] = _createElementVNode("span", null, "关闭其他", -1 /* HOISTED */))], 2 /* CLASS */), _createElementVNode("li", {
    class: _normalizeClass(["el-dropdown-menu__item", {
      'is-disabled': !$setup.visitedRoutes.indexOf($setup.hoverRoute)
    }]),
    onClick: $setup.closeLeftTabs
  }, [_createVNode(_component_vab_icon, {
    icon: "arrow-left-line"
  }), _cache[9] || (_cache[9] = _createElementVNode("span", null, "关闭左侧", -1 /* HOISTED */))], 2 /* CLASS */), _createElementVNode("li", {
    class: _normalizeClass(["el-dropdown-menu__item", {
      'is-disabled': $setup.visitedRoutes.indexOf($setup.hoverRoute) === $setup.visitedRoutes.length - 1
    }]),
    onClick: $setup.closeRightTabs
  }, [_createVNode(_component_vab_icon, {
    icon: "arrow-right-line"
  }), _cache[10] || (_cache[10] = _createElementVNode("span", null, "关闭右侧", -1 /* HOISTED */))], 2 /* CLASS */), _createElementVNode("li", {
    class: "el-dropdown-menu__item",
    onClick: $setup.closeAllTabs
  }, [_createVNode(_component_vab_icon, {
    icon: "close-line"
  }), _cache[11] || (_cache[11] = _createElementVNode("span", null, "关闭全部", -1 /* HOISTED */))])], 4 /* STYLE */)) : _createCommentVNode("v-if", true)]);
}