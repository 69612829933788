import request from '@/utils/request'

export async function login(data: any) {
  return request({
    url: '/api/admin/auth/login',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/api/admin/user/info',
    method: 'get',
  })
}

/**
 * 更新个人资料
 * @param data
 * @returns {*}
 */
export function updateProfile(data: any) {
  return request({
    url: '/api/admin/user/mySet',
    method: 'post',
    data: data,
  })
}

/**
 * 获取当前用户权限
 * @returns {AxiosPromise}
 */
export function getCurrentAccess() {
  return request({
    url: '/api/admin/user/userPermissions',
    method: 'get',
  })
}

/**
 * 获取用户待办统计
 * @returns
 */
export function getUserTodoCount() {
  return request({
    url: '/api/admin/analysis/tenderData',
    method: 'get',
  })
}
