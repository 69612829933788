import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "item-thumbnail-wrap"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 2,
  class: "el-upload-list__item-thumbnail-box"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "el-upload-list__item-actions"
};
const _hoisted_6 = {
  class: "actions-cell"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  key: 0,
  class: "actions-cell"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["onClick"];
const _hoisted_13 = ["onClick"];
const _hoisted_14 = {
  key: 1,
  class: "el-upload-list__item-progress"
};
const _hoisted_15 = {
  key: 2,
  class: "el-upload-list__item-ready"
};
const _hoisted_16 = {
  class: "preview-wrap"
};
const _hoisted_17 = ["src"];
const _hoisted_18 = ["src"];
const _hoisted_19 = ["src"];
const _hoisted_20 = ["src"];
const _hoisted_21 = ["src"];
const _hoisted_22 = ["src"];
const _hoisted_23 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_image = _resolveComponent("el-image");
  const _component_ZoomIn = _resolveComponent("ZoomIn");
  const _component_Delete = _resolveComponent("Delete");
  const _component_DArrowLeft = _resolveComponent("DArrowLeft");
  const _component_ArrowLeft = _resolveComponent("ArrowLeft");
  const _component_ArrowRight = _resolveComponent("ArrowRight");
  const _component_DArrowRight = _resolveComponent("DArrowRight");
  const _component_el_progress = _resolveComponent("el-progress");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_PdfDialog = _resolveComponent("PdfDialog");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: _normalizeClass(["uploader-view", {
      small: _ctx.size === 'small',
      mini: _ctx.size === 'mini',
      'upload-btn': _ctx.calcUploadBtn
    }])
  }, [_createVNode(_component_el_upload, {
    ref: "uploadRef",
    accept: _ctx.calcAccept,
    action: "",
    "before-upload": _ctx.beforeUploadSetting,
    data: {
      folder: _ctx.folder,
      rename: _ctx.rename,
      valueFormat: _ctx.valueFormat
    },
    "file-list": _ctx.fileList,
    "http-request": _ctx.handleUpload,
    limit: _ctx.limit,
    "list-type": "picture-card",
    multiple: _ctx.multiple,
    "on-error": _ctx.handleUploadError,
    "on-exceed": _ctx.handleExceed,
    "on-progress": _ctx.handleUploadProgress,
    "on-success": _ctx.handleUploadSuccess
  }, {
    file: _withCtx(({
      file
    }) => [_createElementVNode("div", _hoisted_1, [file.status === 'success' ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_ctx.getFileType(file.url) === 'image' ? (_openBlock(), _createBlock(_component_el_image, {
      key: 0,
      class: "el-upload-list__item-thumbnail",
      lazy: "",
      src: file.url
    }, null, 8 /* PROPS */, ["src"])) : _ctx.getFileType(file.url) === 'video' ? (_openBlock(), _createElementBlock("video", {
      key: 1,
      class: "el-upload-list__item-thumbnail",
      src: file.url
    }, null, 8 /* PROPS */, _hoisted_2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("img", {
      src: require(`./icon/${_ctx.getFileType(file.url)}.png`)
    }, null, 8 /* PROPS */, _hoisted_4)])), _createElementVNode("span", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_ctx.button.includes('PREVIEW') && _ctx.allowPreview.includes(_ctx.getFileType(file.url)) ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      onClick: $event => _ctx.handlePreview(file)
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_ZoomIn)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), _ctx.button.includes('REMOVE') && !_ctx.calcDisabled ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      onClick: $event => _ctx.handleRemove(file)
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_Delete)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_8)) : _createCommentVNode("v-if", true)]), _ctx.fileList.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_ctx.button.includes('MOVE') && !_ctx.calcDisabled ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_ctx.fileList.indexOf(file) > 0 ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      title: "移至最前",
      onClick: $event => _ctx.handleMove(_ctx.fileList.indexOf(file), 'first')
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_DArrowLeft)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_10)) : _createCommentVNode("v-if", true), _ctx.fileList.indexOf(file) > 0 ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      title: "左移",
      onClick: $event => _ctx.handleMove(_ctx.fileList.indexOf(file), 'left')
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_ArrowLeft)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_11)) : _createCommentVNode("v-if", true), _ctx.fileList.indexOf(file) < _ctx.fileList.length - 1 ? (_openBlock(), _createElementBlock("span", {
      key: 2,
      title: "右移",
      onClick: $event => _ctx.handleMove(_ctx.fileList.indexOf(file), 'right')
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_12)) : _createCommentVNode("v-if", true), _ctx.fileList.indexOf(file) < _ctx.fileList.length - 1 ? (_openBlock(), _createElementBlock("span", {
      key: 3,
      title: "移至最后",
      onClick: $event => _ctx.handleMove(_ctx.fileList.indexOf(file), 'last')
    }, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_DArrowRight)]),
      _: 1 /* STABLE */
    })], 8 /* PROPS */, _hoisted_13)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), file.status === 'uploading' ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_el_progress, {
      percentage: file.percentage,
      "stroke-width": 18,
      "text-inside": true
    }, null, 8 /* PROPS */, ["percentage"])])) : _createCommentVNode("v-if", true), file.status === 'ready' ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, null, 512 /* NEED_PATCH */)), [[_directive_loading, true]]) : _createCommentVNode("v-if", true)])]),
    default: _withCtx(() => [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_Plus)]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["accept", "before-upload", "data", "file-list", "http-request", "limit", "multiple", "on-error", "on-exceed", "on-progress", "on-success"])], 2 /* CLASS */), _createVNode(_component_el_dialog, {
    modelValue: _ctx.preview.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.preview.visible = $event),
    "append-to-body": ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_ctx.getFileType(_ctx.preview.url) === 'image' ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: _ctx.preview.url
    }, null, 8 /* PROPS */, _hoisted_17)) : _ctx.getFileType(_ctx.preview.url) === 'video' ? (_openBlock(), _createElementBlock("video", {
      key: 1,
      controls: "",
      src: _ctx.preview.url
    }, null, 8 /* PROPS */, _hoisted_18)) : _ctx.getFileType(_ctx.preview.url) === 'audio' ? (_openBlock(), _createElementBlock("audio", {
      key: 2,
      controls: "",
      src: _ctx.preview.url
    }, null, 8 /* PROPS */, _hoisted_19)) : _ctx.getFileType(_ctx.preview.url) === 'excel' ? (_openBlock(), _createElementBlock("iframe", {
      key: 3,
      frameborder: "0",
      src: `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(_ctx.preview.url)}`
    }, null, 8 /* PROPS */, _hoisted_20)) : _ctx.getFileType(_ctx.preview.url) === 'word' ? (_openBlock(), _createElementBlock("iframe", {
      key: 4,
      frameborder: "0",
      src: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(_ctx.preview.url)}`
    }, null, 8 /* PROPS */, _hoisted_21)) : _ctx.getFileType(_ctx.preview.url) === 'ppt' ? (_openBlock(), _createElementBlock("iframe", {
      key: 5,
      frameborder: "0",
      src: `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(_ctx.preview.url)}`
    }, null, 8 /* PROPS */, _hoisted_22)) : _ctx.getFileType(_ctx.preview.url) === 'pdf' ? (_openBlock(), _createElementBlock("embed", {
      key: 6,
      src: _ctx.preview.url,
      type: "application/pdf"
    }, null, 8 /* PROPS */, _hoisted_23)) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_PdfDialog, {
    ref: "pdfDialogRef"
  }, null, 512 /* NEED_PATCH */)], 64 /* STABLE_FRAGMENT */);
}