import { defineComponent, provide, reactive, toRefs } from 'vue';
const __default__ = defineComponent({
  name: 'Descriptions',
  props: {
    column: {
      type: Number,
      default: 3
    },
    title: {
      type: String,
      default: ''
    },
    extra: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '150px'
    }
  },
  setup(props) {
    provide('column', props.column);
    const state = reactive({});
    return {
      ...toRefs(state)
    };
  }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "54b5e9ea-column": _ctx.column,
    "54b5e9ea-labelWidth": _ctx.labelWidth
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;