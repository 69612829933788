import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  class: "descriptions-view"
};
const _hoisted_2 = {
  class: "descriptions-header"
};
const _hoisted_3 = {
  class: "descriptions-title"
};
const _hoisted_4 = {
  key: 0,
  class: "descriptions-extra"
};
const _hoisted_5 = {
  class: "descriptions-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */), _ctx.extra ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */)) : _renderSlot(_ctx.$slots, "extra", {
    key: 1
  }, undefined, true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}