import { defineComponent, defineAsyncComponent, reactive, toRefs } from 'vue';
import { useUserStore } from '@/store/modules/user';
export default defineComponent({
  name: 'Personal',
  components: {
    PersonalFrom: defineAsyncComponent(() => import('./form'))
  },
  emits: ['on-submit'],
  setup(props, {
    emit
  }) {
    const state = reactive({
      personalFormRef: null,
      visible: false,
      user: {
        name: '',
        avatar: '',
        phone: '',
        department: null,
        role: null,
        post: null
      }
    });
    const fetchData = () => {
      const {
        name,
        avatar,
        phone,
        department,
        role,
        post
      } = useUserStore();
      state.user.name = name;
      state.user.avatar = avatar;
      state.user.phone = phone;
      state.user.department = department;
      state.user.role = role;
      state.user.post = post;
    };
    const show = () => {
      fetchData();
      state.visible = true;
    };
    const close = () => {
      state.visible = false;
    };
    const handleUpdate = () => {
      state.personalFormRef.show();
    };
    const refresh = () => {
      fetchData();
      emit('on-submit');
    };
    return {
      ...toRefs(state),
      fetchData,
      show,
      close,
      handleUpdate,
      refresh
    };
  }
});