import { useRoute, useRouter } from 'vue-router';
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
import { isExternal } from '@/utils/validate';
import { isHashRouterMode } from '@/config';
export default /*@__PURE__*/_defineComponent({
  __name: 'VabMenuItem',
  props: {
    itemOrMenu: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var props = __props;
    var route = useRoute();
    var router = useRouter();
    var $pub = inject('$pub');
    var settingsStore = useSettingsStore();
    var device = storeToRefs(settingsStore).device;
    var foldSideBar = settingsStore.foldSideBar;
    var handleLink = function () {
      var routePath = props.itemOrMenu.path;
      var target = props.itemOrMenu.meta.target;
      if (target === '_blank') {
        if (isExternal(routePath)) window.open(routePath);else if (route.path !== routePath) isHashRouterMode ? window.open("/#".concat(routePath)) : window.open(routePath);
      } else {
        if (isExternal(routePath)) window.location.href = routePath;else if (route.path !== routePath) {
          if (device.value === 'mobile') foldSideBar();
          router.push(props.itemOrMenu.path);
        } else $pub('reload-router-view');
      }
    };
    var __returned__ = {
      props: props,
      route: route,
      router: router,
      $pub: $pub,
      settingsStore: settingsStore,
      device: device,
      foldSideBar: foldSideBar,
      handleLink: handleLink
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});