import { defineComponent, defineAsyncComponent, inject, reactive, computed, watchEffect, nextTick, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
import useUpload from '@/composables/useUpload';
import { Plus, ZoomIn, Delete, ArrowLeft, ArrowRight, DArrowRight, DArrowLeft } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'Uploader',
  components: {
    Plus,
    ZoomIn,
    Delete,
    ArrowLeft,
    ArrowRight,
    DArrowRight,
    DArrowLeft,
    PdfDialog: defineAsyncComponent(() => import('@/components/pdfDialog'))
  },
  props: {
    modelValue: {
      type: [String, Array],
      default: null
    },
    target: {
      type: [String, Object, Number],
      default: null
    },
    limit: {
      type: Number,
      default: Infinity
    },
    multiple: {
      type: Boolean,
      default: false
    },
    field: {
      // 当modelValue为数组时图片对应的字段名
      type: String,
      default: 'url'
    },
    name: {
      // 上传的文件字段名
      type: String,
      default: 'file'
    },
    disabled: {
      // 当前组件是否可操作
      type: Boolean,
      default: false
    },
    data: {
      // 上传时附带的额外参数
      type: Object,
      default() {
        return {};
      }
    },
    accept: {
      type: Array,
      default() {
        return []; // 格式： ['.jpg', '.jpeg', '.png', '.gif']
      }
    },
    size: {
      validator(value) {
        return ['small', 'default', 'mini'].includes(value);
      },
      default: 'default'
    },
    folder: {
      // 上传存放的文件夹
      type: String,
      default: null
    },
    rename: {
      // 文件是否重命名
      type: Boolean,
      default: true
    },
    valueFormat: {
      // 返回格式
      type: String,
      default: 'url'
    },
    button: {
      // MOVE, REMOVE, PREVIEW
      type: Array,
      default() {
        return ['PREVIEW'];
      }
    }
  },
  emits: ['on-success', 'update:modelValue'],
  setup(props, {
    emit
  }) {
    const $baseMessage = inject('$baseMessage');
    const {
      uploadConfig,
      handleUpload,
      getUrlName,
      handleGlobalBeforeUpload,
      handleGlobalUploadError,
      getFileType
    } = useUpload();
    const state = reactive({
      uploadRef: null,
      pdfDialogRef: null,
      allowPreview: ['image', 'audio', 'video', 'excel', 'word', 'ppt', 'pdf'],
      fileList: [],
      preview: {
        visible: false,
        url: ''
      }
    });
    const calcAccept = computed(() => {
      return props.accept.join(',');
    });
    const calcDisabled = computed(() => {
      return props.disabled || uploadConfig.loading;
    });
    const calcUploadBtn = computed(() => {
      let tempCheck = false;
      if (!props.disabled) {
        if (state.fileList.length < props.limit) {
          tempCheck = true;
        }
      }
      return tempCheck;
    });
    const beforeUploadSetting = file => {
      return handleGlobalBeforeUpload(file);
    };
    const handleExceed = () => {
      $baseMessage(`文件不能超过${props.limit}个.`, 'error', 'vab-hey-message-error');
    };
    const handleUploadSuccess = res => {
      if (!res) {
        return;
      }
      let tempArray = [];
      for (let i in res.data) {
        if (res.data[i]) {
          tempArray.push(res.data[i]);
        }
      }
      if (typeof props.modelValue === 'string') {
        if (tempArray.length) {
          if (props.valueFormat === 'object') {
            emit('update:modelValue', tempArray[0].url);
          } else {
            emit('update:modelValue', tempArray[0]);
          }
        }
      }
      emit('on-success', tempArray, props.target);
    };
    const handleUploadProgress = (percentage, file) => {
      for (let i in state['uploadRef'].uploadFiles) {
        if (state['uploadRef'].uploadFiles[i].name === file.name) {
          state['uploadRef'].uploadFiles[i].status = 'uploading';
          state['uploadRef'].uploadFiles[i].percentage = percentage;
          break;
        }
      }
    };
    const handleUploadError = (err, file, fileList) => {
      handleGlobalUploadError(err, file, fileList);
    };
    const handleRemove = file => {
      let tempIndex = state.fileList.indexOf(file);
      if (tempIndex > -1) {
        if (typeof props.modelValue === 'string') {
          emit('update:modelValue', '');
        } else if (props.modelValue instanceof Array && props.modelValue.length > tempIndex) {
          let tempList = [...props.modelValue];
          tempList.splice(tempIndex, 1);
          emit('update:modelValue', tempList);
        }
      }
    };
    const handleMove = (index, moveTo) => {
      if (calcDisabled.value._value) {
        return;
      }
      let fileList = state.fileList;
      if (index === 0 && (moveTo === 'left' || moveTo === 'first') || index === fileList.length - 1 && (moveTo === 'right' || moveTo === 'last')) {
        return;
      }
      let tempList = [...props.modelValue];
      if (['first', 'last'].includes(moveTo)) {
        let tempRecord = tempList[index];
        tempList.splice(index, 1);
        if (moveTo === 'first') {
          tempList.unshift(tempRecord);
        } else {
          tempList.push(tempRecord);
        }
      } else {
        let moveIndex = moveTo === 'left' ? index - 1 : index + 1;
        tempList[index] = tempList.splice(moveIndex, 1, tempList[index])[0];
      }
      emit('update:modelValue', tempList);
    };
    const handlePreview = file => {
      if (!file.url) {
        return;
      }
      if (getFileType(file.url) === 'pdf') {
        state.pdfDialogRef.show(file.url);
      } else {
        state.preview.url = file.url;
        state.preview.visible = true;
      }
    };
    watchEffect(() => {
      if (props.modelValue !== '' && props.modelValue !== undefined && props.modelValue !== null) {
        let tempFileList = [];
        if (props.modelValue instanceof Array) {
          for (let i in props.modelValue) {
            let tempUrl = '';
            if (typeof props.modelValue[i] === 'string') {
              tempUrl = props.modelValue[i];
            } else {
              tempUrl = props.modelValue[i][props.field];
            }
            let tempRecord = {
              name: getUrlName(tempUrl),
              url: tempUrl
            };
            tempFileList.push(tempRecord);
          }
        } else {
          tempFileList.push({
            url: props.modelValue,
            name: getUrlName(props.modelValue)
          });
        }
        nextTick(() => {
          state.fileList = [...tempFileList];
        });
      } else {
        nextTick(() => {
          state.fileList = [];
        });
      }
    });
    return {
      ...toRefs(state),
      calcAccept,
      calcDisabled,
      calcUploadBtn,
      beforeUploadSetting,
      handleExceed,
      handleUpload,
      handleUploadSuccess,
      handleUploadProgress,
      handleUploadError,
      handleRemove,
      handleMove,
      getFileType,
      handlePreview
    };
  }
});