import { __assign, __awaiter, __generator } from "tslib";
import { isJson } from '@/utils/validate';
import { getLocalUrl } from '@/api/common';
import { updateProfile } from '@/api/user';
import { logo as _logo, title as _title, layout, themeName, background, columnStyle, fixedHeader, foldSidebar, menuWidth, showProgressBar, showTabs, showTabsIcon, showRefresh, showTheme, showFullScreen, showPageTransition, showLock, tabsBarStyle } from '@/config';
var defaultTheme = {
  layout: layout,
  themeName: themeName,
  background: background,
  columnStyle: columnStyle,
  fixedHeader: fixedHeader,
  foldSidebar: foldSidebar,
  menuWidth: menuWidth,
  showProgressBar: showProgressBar,
  showTabs: showTabs,
  showTabsIcon: showTabsIcon,
  showRefresh: showRefresh,
  showTheme: showTheme,
  showFullScreen: showFullScreen,
  showPageTransition: showPageTransition,
  showLock: showLock,
  tabsBarStyle: tabsBarStyle
};
var getLocalStorage = function (key) {
  var value = localStorage.getItem(key);
  return value && isJson(value) ? JSON.parse(value) : false;
};
var theme = getLocalStorage('theme') || __assign({}, defaultTheme);
var _a = getLocalStorage('collapse').collapse,
  collapse = _a === void 0 ? foldSidebar : _a;
var _b = getLocalStorage('lock').lock,
  lock = _b === void 0 ? false : _b;
var _c = getLocalStorage('logo').logo,
  logo = _c === void 0 ? _logo : _c;
var _d = getLocalStorage('title').title,
  title = _d === void 0 ? _title : _d;
export var useSettingsStore = defineStore('settings', {
  state: function () {
    return {
      theme: theme,
      device: 'desktop',
      collapse: collapse,
      lock: lock,
      logo: logo,
      title: title,
      echartsGraphic1: ['#3ED572', '#399efd'],
      echartsGraphic2: ['#399efd', '#8cc8ff'],
      fullscreen: false,
      webview: false
    };
  },
  getters: {
    getTheme: function (state) {
      return state.theme;
    },
    getDevice: function (state) {
      return state.device;
    },
    getCollapse: function (state) {
      return state.collapse;
    },
    getLock: function (state) {
      return state.lock;
    },
    getLogo: function (state) {
      return state.logo;
    },
    getTitle: function (state) {
      return state.title;
    },
    getFullscreen: function (state) {
      return state.fullscreen;
    },
    getWebview: function (state) {
      return state.webview;
    }
  },
  actions: {
    updateState: function (obj) {
      var _this = this;
      Object.getOwnPropertyNames(obj).forEach(function (key) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _this[key] = obj[key];
        localStorage.setItem(key, typeof obj[key] == 'string' ? "{\"".concat(key, "\":\"").concat(obj[key], "\"}") : "{\"".concat(key, "\":").concat(obj[key], "}"));
      });
    },
    saveTheme: function () {
      localStorage.setItem('theme', JSON.stringify(this.theme));
    },
    resetTheme: function () {
      this.theme = __assign({}, defaultTheme);
      localStorage.removeItem('theme');
      this.updateTheme();
    },
    updateTheme: function () {
      var _this = this;
      var index = this.theme.themeName.indexOf('-');
      var themeName = this.theme.themeName.substring(0, index) || 'blue';
      var variables = require("@vab/styles/variables/vab-".concat(themeName, "-variables.module.scss"));
      if (variables.default) variables = variables.default;
      Object.keys(variables).forEach(function (key) {
        if (key.startsWith('vab-')) {
          useCssVar(key.replace('vab-', '--el-'), ref(null)).value = variables[key];
        }
      });
      this.echartsGraphic1 = [variables['vab-color-transition'], variables['vab-color-primary']];
      this.echartsGraphic2 = [variables['vab-color-primary-light-5'], variables['vab-color-primary']];
      var menuBackground = this.theme.themeName.split('-')[1] || this.theme.themeName;
      document.getElementsByTagName('body')[0].className = "vab-theme-".concat(menuBackground);
      if (this.theme.background !== 'none') document.getElementsByTagName('body')[0].classList.add(this.theme.background);
      nextTick(function () {
        var el = ref(null);
        if (_this.theme.menuWidth && _this.theme.menuWidth.endsWith('px')) useCssVar('--el-left-menu-width', el).value = _this.theme.menuWidth;else useCssVar('--el-left-menu-width', el).value = '266px';
      });
    },
    toggleCollapse: function () {
      this.collapse = !this.collapse;
      localStorage.setItem('collapse', "{\"collapse\":".concat(this.collapse, "}"));
    },
    toggleDevice: function (device) {
      this.updateState({
        device: device
      });
    },
    openSideBar: function () {
      this.updateState({
        collapse: false
      });
    },
    foldSideBar: function () {
      this.updateState({
        collapse: true
      });
    },
    handleLock: function () {
      this.updateState({
        lock: true
      });
    },
    handleUnLock: function () {
      this.updateState({
        lock: false
      });
    },
    changeLogo: function (logo) {
      this.updateState({
        logo: logo
      });
    },
    changeTitle: function (title) {
      this.updateState({
        title: title
      });
    },
    setFullscreen: function (fullscreen) {
      this.fullscreen = fullscreen;
    },
    setWebview: function (webview) {
      this.webview = webview;
    },
    updateLocalInfo: function () {
      return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, getLocalUrl()];
            case 1:
              data = _a.sent().data;
              if (data && data.url) {
                axios.get(data.url, {
                  timeout: 1500
                }).then(function () {
                  updateProfile({
                    is_local: 1
                  });
                }).catch(function () {
                  updateProfile({
                    is_local: 0
                  });
                });
              }
              return [2 /*return*/];
          }
        });
      });
    }
  }
});