import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "custom-layouts-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VabRouterView = _resolveComponent("VabRouterView");
  const _component_el_backtop = _resolveComponent("el-backtop");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_VabRouterView), _createVNode(_component_el_backtop, {
    target: "#app"
  })]);
}