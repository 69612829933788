import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "descriptions-label"
};
const _hoisted_2 = {
  class: "descriptions-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["descriptions-item-view", _ctx.classArray])
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])], 2 /* CLASS */);
}