import { defineComponent, defineAsyncComponent, inject, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import "core-js/modules/es.array.push.js";
import { useUserStore } from '@/store/modules/user';
import { updateProfile } from '@/api/user';
import { toLoginRoute } from '@/utils/routes';
export default defineComponent({
  components: {
    Personal: defineAsyncComponent(() => import('./personal'))
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $baseMessage = inject('$baseMessage');
    const {
      avatar,
      username,
      name,
      logout
    } = useUserStore();
    const state = reactive({
      personalRef: null,
      scanRef: null,
      avatar,
      name,
      username,
      active: false
    });
    const handleVisibleChange = val => {
      state.active = val;
    };
    const handleCommand = async command => {
      switch (command) {
        case 'logout':
          await logout();
          await router.push(toLoginRoute(route.fullPath));
          break;
        case 'scan':
          state.scanRef.show();
          break;
        case 'personalCenter':
          state.personalRef.show();
          break;
      }
    };
    const refresh = () => {
      const {
        avatar,
        username,
        name
      } = useUserStore();
      state.avatar = avatar;
      state.username = username;
      state.name = name;
    };
    const handleScanSubmit = ({
      user
    }) => {
      if (user) {
        updateProfile({
          openid: user.openid
        }).then(async () => {
          const {
            getUserInfo
          } = useUserStore();
          await getUserInfo();
          $baseMessage('绑定成功', 'success', 'vab-hey-message-success');
        });
      }
    };
    return {
      ...toRefs(state),
      handleVisibleChange,
      handleCommand,
      refresh,
      handleScanSubmit
    };
  }
});