import { storeToRefs } from 'pinia';
import { defineComponent as _defineComponent } from 'vue';
import { useRoutesStore } from '@/store/modules/routes';
import variables from '@vab/styles/variables/variables.module.scss';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: 'horizontal'
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var routesStore = useRoutesStore();
    var _b = storeToRefs(routesStore),
      activeMenu = _b.getActiveMenu,
      routes = _b.getRoutes;
    var __returned__ = {
      routesStore: routesStore,
      activeMenu: activeMenu,
      routes: routes,
      get variables() {
        return variables;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});