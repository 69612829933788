import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/tender',
    name: 'Tender',
    component: Layout,
    meta: {
      title: '标书管理',
      icon: 'book-3-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'project',
        name: 'TenderProject',
        component: () => import('@/views/tender/project/index.vue'),
        meta: {
          title: '项目管理',
          access: true,
        },
      },
      {
        path: 'document',
        name: 'TenderDocument',
        component: () => import('@/views/tender/document/index.vue'),
        meta: {
          title: '项目标书',
          access: true,
        },
      },
      {
        path: 'document/team',
        name: 'TenderDocumentTeam',
        component: () => import('@/views/tender/document/team/index.vue'),
        meta: {
          title: '团队标书',
          access: true,
        },
      },
      {
        path: 'us',
        name: 'TenderUs',
        component: () => import('@/views/tender/us/index.vue'),
        meta: {
          title: '我的任务',
          access: true,
        },
      },
      {
        path: 'todo',
        name: 'TenderTodo',
        component: () => import('@/views/tender/todo/index.vue'),
        meta: {
          title: '审核标书',
          access: true,
        },
      },
    ],
  },
]
