import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { __awaiter, __generator } from "tslib";
/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { useSettingsStore } from './settings';
import { getUserInfo, login, getCurrentAccess } from '@/api/user';
import { getMenu } from '@/api/menu';
import { getToken, removeToken, setToken } from '@/utils/token';
import { formatAccessConfig } from '@/utils/tools';
import { resetRouter } from '@/router';
import { gp } from '@gp';
export var useUserStore = defineStore('user', {
  state: function () {
    return {
      token: getToken(),
      id: '',
      username: '',
      name: '',
      avatar: '',
      phone: '',
      role: null,
      post: null,
      department: null,
      leader: null,
      access: [],
      local: false
    };
  },
  getters: {
    getToken: function (state) {
      return state.token;
    },
    getId: function (state) {
      return state.id;
    },
    getUsername: function (state) {
      return state.username;
    },
    getName: function (state) {
      return state.name;
    },
    getAvatar: function (state) {
      return state.avatar;
    },
    getPhone: function (state) {
      return state.phone;
    },
    getRole: function (state) {
      return state.role;
    },
    getDepartment: function (state) {
      return state.department;
    },
    getPost: function (state) {
      return state.post;
    },
    getLeader: function (state) {
      return state.leader;
    },
    getAccess: function (state) {
      return state.access;
    },
    getLocal: function (state) {
      return state.local;
    }
  },
  actions: {
    setToken: function (token) {
      this.token = token;
      setToken(token);
    },
    setId: function (id) {
      this.id = id;
    },
    setUsername: function (username) {
      this.username = username;
    },
    setName: function (name) {
      this.name = name;
    },
    setAvatar: function (avatar) {
      this.avatar = avatar;
    },
    setPhone: function (phone) {
      this.phone = phone;
    },
    setRole: function (role) {
      var tempRole = {
        id: [],
        name: []
      };
      if (role) {
        if (role.id) {
          tempRole.id = role.id.split(',').map(Number);
        }
        if (role.name) {
          tempRole.name = role.name.split(',');
        }
      }
      this.role = tempRole;
    },
    setDepartment: function (department) {
      this.department = department;
    },
    setPost: function (post) {
      this.post = post;
    },
    setLeader: function (leader) {
      this.leader = leader;
    },
    setAccess: function (_a) {
      var access = _a.access,
        accessConfig = _a.accessConfig;
      var tempArray = [];
      if (access && access.length) {
        for (var i in accessConfig) {
          for (var j in access) {
            if (access[j].billId === accessConfig[i].billId && accessConfig[i].mark & access[j].accessMark) {
              tempArray = tempArray.concat(accessConfig[i].tree);
              break;
            }
          }
        }
      }
      this.access = Array.from(new Set(tempArray));
    },
    setLocal: function (local) {
      this.local = local;
    },
    afterLogin: function (token) {
      var settingsStore = useSettingsStore();
      if (token) {
        this.setToken(token);
        var hour = new Date().getHours();
        var thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
        gp.$baseNotify("\u6B22\u8FCE\u767B\u5F55".concat(settingsStore.title), "".concat(thisTime, "\uFF01"));
      } else {
        var err = "\u767B\u5F55\u63A5\u53E3\u5F02\u5E38";
        gp.$baseMessage(err, 'error', 'vab-hey-message-error');
        throw err;
      }
    },
    /**
     * @description 登录
     * @param {*} userInfo
     */
    login: function (userInfo) {
      return __awaiter(this, void 0, void 0, function () {
        var _a, token_type, access_token;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              return [4 /*yield*/, login(userInfo)];
            case 1:
              _a = _b.sent().data, token_type = _a.token_type, access_token = _a.access_token;
              this.afterLogin("".concat(token_type, " ").concat(access_token));
              return [2 /*return*/];
          }
        });
      });
    },
    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    getUserInfo: function () {
      return __awaiter(this, void 0, void 0, function () {
        var _a, id, username, name, avatar, phone, role_ids, roles, parent_dep_id, department, post, leader, err, menuRes, accessConfig, data;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              return [4 /*yield*/, getUserInfo()
              /**
               * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
               * username {String}
               * avatar {String}
               * roles {List}
               * ability {List}
               */];
            case 1:
              _a = _b.sent().data, id = _a.id, username = _a.username, name = _a.name, avatar = _a.avatar, phone = _a.phone, role_ids = _a.role_ids, roles = _a.roles, parent_dep_id = _a.parent_dep_id, department = _a.department, post = _a.post, leader = _a.leader;
              if (!!id) return [3 /*break*/, 2];
              err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确';
              gp.$baseMessage(err, 'error', 'vab-hey-message-error');
              throw err;
            case 2:
              this.setId(id);
              this.setUsername(username);
              this.setName(name);
              this.setAvatar(avatar);
              this.setPhone(phone);
              this.setRole({
                id: role_ids,
                name: roles
              });
              this.setDepartment(Object.assign({}, department, {
                parent_dep_id: parent_dep_id
              }));
              this.setPost(post);
              this.setLeader(leader);
              return [4 /*yield*/, getMenu()];
            case 3:
              menuRes = _b.sent();
              accessConfig = formatAccessConfig(menuRes.data);
              return [4 /*yield*/, getCurrentAccess()];
            case 4:
              data = _b.sent().data;
              this.setAccess({
                access: data,
                accessConfig: accessConfig
              });
              _b.label = 5;
            case 5:
              return [2 /*return*/];
          }
        });
      });
    },
    /**
     * @description 退出登录
     */
    logout: function () {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, this.resetAll()
              // 解决横向布局退出登录显示不全的bug
              ];
            case 1:
              _a.sent();
              // 解决横向布局退出登录显示不全的bug
              location.reload();
              return [2 /*return*/];
          }
        });
      });
    },
    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    resetAll: function () {
      return __awaiter(this, void 0, void 0, function () {
        var routesStore, tabsStore;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              this.setToken('');
              this.setId('');
              this.setUsername('');
              this.setName('');
              this.setAvatar('');
              this.setPhone('');
              this.setRole(null);
              this.setAccess({
                access: null
              });
              routesStore = useRoutesStore();
              tabsStore = useTabsStore();
              tabsStore.delAllVisitedRoutes();
              routesStore.clearRoutes();
              return [4 /*yield*/, resetRouter()];
            case 1:
              _a.sent();
              removeToken();
              return [2 /*return*/];
          }
        });
      });
    }
  }
});