import { defineComponent, reactive, toRefs } from 'vue';
import "core-js/modules/es.array.push.js";
export default defineComponent({
  name: 'DescriptionsItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    span: {
      type: Number,
      default: 1
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      classArray: []
    });
    state.classArray.push(`span-${props.span}`);
    if (props.required) {
      state.classArray.push('required');
    }
    return {
      ...toRefs(state)
    };
  }
});