import Access from './Access/index.vue'
import Uploader from './Uploader/index.vue'
import QueryForm from './QueryForm/index.vue'
import QueryFormLeftPanel from './QueryForm/components/QueryFormLeftPanel.vue'
import QueryFormRightPanel from './QueryForm/components/QueryFormRightPanel.vue'
import Descriptions from './Descriptions/index.vue'
import DescriptionsItem from './Descriptions/components/DescriptionsItem.vue'

export function setupComponents(app: any) {
  app.component('Access', Access)
  app.component('Uploader', Uploader)
  app.component('QueryForm', QueryForm)
  app.component('QueryFormLeftPanel', QueryFormLeftPanel)
  app.component('QueryFormRightPanel', QueryFormRightPanel)
  app.component('Descriptions', Descriptions)
  app.component('DescriptionsItem', DescriptionsItem)
}
