import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "vab-app-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_VabRouterView = _resolveComponent("VabRouterView");
  var _component_vab_footer = _resolveComponent("vab-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", {
    class: _normalizeClass({
      'vab-fullscreen': $setup.fullscreen
    })
  }, [_createVNode(_component_VabRouterView)], 2 /* CLASS */), _createVNode(_component_vab_footer)]);
}