import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { nextTick, watchEffect } from 'vue';
import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { isExternal } from '@/utils/validate';
import { useRoutesStore } from '@/store/modules/routes';
import { defaultOpeneds, openFirstMenu, uniqueOpened } from '@/config';
import { useSettingsStore } from '@/store/modules/settings';
import variables from '@vab/styles/variables/variables.module.scss';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var route = useRoute();
    var router = useRouter();
    var settingsStore = useSettingsStore();
    var _b = storeToRefs(settingsStore),
      theme = _b.theme,
      collapse = _b.collapse;
    var foldSideBar = settingsStore.foldSideBar,
      openSideBar = settingsStore.openSideBar;
    var routesStore = useRoutesStore();
    var _c = storeToRefs(routesStore),
      tab = _c.getTab,
      tabMenu = _c.getTabMenu,
      activeMenu = _c.getActiveMenu,
      routes = _c.getRoutes,
      partialRoutes = _c.getPartialRoutes;
    var handleTabClick = function () {
      nextTick(function () {
        if (isExternal(tabMenu.value.path)) {
          window.open(tabMenu.value.path);
          setTimeout(function () {
            router.push('/');
          }, 1000);
        } else if (openFirstMenu) router.push(tabMenu.value.redirect || tabMenu.value);
      });
    };
    watchEffect(function () {
      var foldUnfold = document.querySelector('.fold-unfold');
      if (theme.value.layout === 'column' && route.meta.noColumn) {
        foldSideBar();
        if (foldUnfold) foldUnfold.style = 'display:none';
      } else {
        openSideBar();
        if (foldUnfold) foldUnfold.style = '';
      }
    });
    var __returned__ = {
      route: route,
      router: router,
      settingsStore: settingsStore,
      theme: theme,
      collapse: collapse,
      foldSideBar: foldSideBar,
      openSideBar: openSideBar,
      routesStore: routesStore,
      tab: tab,
      tabMenu: tabMenu,
      activeMenu: activeMenu,
      routes: routes,
      partialRoutes: partialRoutes,
      handleTabClick: handleTabClick,
      get defaultOpeneds() {
        return defaultOpeneds;
      },
      get uniqueOpened() {
        return uniqueOpened;
      },
      get variables() {
        return variables;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});