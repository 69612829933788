import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    collapse: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    fixedHeader: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    showTabs: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    device: {
      type: String,
      default: function () {
        return 'desktop';
      }
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var settingsStore = useSettingsStore();
    var foldSideBar = settingsStore.foldSideBar;
    var __returned__ = {
      settingsStore: settingsStore,
      foldSideBar: foldSideBar
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});