import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/archive',
    name: 'Archive',
    component: Layout,
    meta: {
      title: '企业档案',
      icon: 'award-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'basic',
        name: 'ArchiveBasic',
        component: () => import('@/views/archive/basic/index.vue'),
        meta: {
          title: '基本信息',
          access: true,
        },
      },
      {
        path: 'license',
        name: 'ArchiveLicense',
        component: () => import('@/views/archive/license/index.vue'),
        meta: {
          title: '公司证照',
          access: true,
        },
      },
      {
        path: 'cv',
        name: 'ArchiveCv',
        component: () => import('@/views/archive/cv/index.vue'),
        meta: {
          title: '业绩履历',
          access: true,
        },
      },
      {
        path: 'finance',
        name: 'ArchiveFinance',
        component: () => import('@/views/archive/finance/index.vue'),
        meta: {
          title: '财务报表',
          access: true,
        },
      },
      {
        path: 'certificate',
        name: 'ArchiveCertificate',
        component: () => import('@/views/archive/certificate/index.vue'),
        meta: {
          title: '资质荣誉',
          access: true,
        },
      },
      {
        path: 'equipment',
        name: 'ArchiveEquipment',
        component: () => import('@/views/archive/equipment/index.vue'),
        meta: {
          title: '设备资料',
          access: true,
        },
      },
      {
        path: 'car',
        name: 'ArchiveCar',
        component: () => import('@/views/archive/car/index.vue'),
        meta: {
          title: '车辆资料',
          access: true,
        },
      },
      {
        path: 'other',
        name: 'ArchiveOther',
        component: () => import('@/views/archive/other/index.vue'),
        meta: {
          title: '其他资料',
          access: true,
        },
      },
      {
        path: 'qualification',
        name: 'ArchiveQualification',
        component: () => import('@/views/archive/qualification/index.vue'),
        meta: {
          title: '人员资质',
          access: true,
        },
      },
      {
        path: 'patent',
        name: 'ArchivePatent',
        component: () => import('@/views/archive/patent/index.vue'),
        meta: {
          title: '专利证书',
          access: true,
        },
      },
      {
        path: 'software',
        name: 'ArchiveSoftware',
        component: () => import('@/views/archive/software/index.vue'),
        meta: {
          title: '软著证书',
          access: true,
        },
      },
      {
        path: 'inspection',
        name: 'ArchiveInspection',
        component: () => import('@/views/archive/inspection/index.vue'),
        meta: {
          title: '检测报告',
          access: true,
        },
      },
      {
        path: 'type',
        name: 'ArchiveType',
        component: () => import('@/views/archive/type/index.vue'),
        meta: {
          title: '档案类型',
          access: true,
        },
      },
    ],
  },
]
