import CustomLayouts from '@/layouts/index.vue'
export default [
  {
    path: '/aside',
    name: 'Aside',
    component: CustomLayouts,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: 'material',
        name: 'AsideMaterial',
        component: () => import('@/views/aside/material/index.vue'),
        meta: {
          hidden: true,
          title: '图片素材',
        },
      },
      {
        path: 'template',
        name: 'AsideTemplate',
        component: () => import('@/views/aside/template/index.vue'),
        meta: {
          hidden: true,
          title: '标书模板',
        },
      },
      {
        path: 'template/preview/:id',
        name: 'AsideTemplatePreview',
        component: () => import('@/views/aside/template/preview/index.vue'),
        meta: {
          hidden: true,
          title: '标书模板预览',
        },
      },
      {
        path: 'template/update/:id',
        name: 'AsideTemplateUpdate',
        component: () => import('@/views/aside/template/update/index.vue'),
        meta: {
          hidden: true,
          title: '标书模板编辑',
        },
      },
      {
        path: 'template/add',
        name: 'AsideTemplateAdd',
        component: () => import('@/views/aside/template/add/index.vue'),
        meta: {
          hidden: true,
          title: '标书模板',
        },
      },
      {
        path: 'archive/:type?',
        name: 'AsideArchive',
        component: () => import('@/views/aside/archive/index.vue'),
        meta: {
          hidden: true,
          title: '企业档案',
        },
      },
      {
        path: 'archive/preview/:id',
        name: 'AsideArchivePreview',
        component: () => import('@/views/aside/archive/preview/index.vue'),
        meta: {
          hidden: true,
          title: '档案预览',
        },
      },
      {
        path: 'archive/update/:id',
        name: 'AsideArchiveUpdate',
        component: () => import('@/views/aside/archive/update/index.vue'),
        meta: {
          hidden: true,
          title: '企业档案详情',
        },
      },
      {
        path: 'task/update/:id/:mode?',
        name: 'AsideTaskUpdate',
        component: () => import('@/views/aside/task/update/index.vue'),
        meta: {
          hidden: true,
          title: '任务详情',
        },
      },
      {
        path: 'document/update/:id',
        name: 'AsideDocumentUpdate',
        component: () => import('@/views/aside/document/update/index.vue'),
        meta: {
          hidden: true,
          title: '标书详情',
        },
      },
    ],
  },
]
