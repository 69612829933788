import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/template',
    name: 'Template',
    component: Layout,
    meta: {
      title: '模板',
      icon: 'book-read-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'index',
        name: 'TemplateIndex',
        component: () => import('@/views/template/index.vue'),
        meta: {
          title: '标书模板',
          access: true,
        },
      },
      {
        path: 'type',
        name: 'TemplateType',
        component: () => import('@/views/template/type/index.vue'),
        meta: {
          title: '模板类型',
          access: true,
        },
      },
    ],
  },
]
