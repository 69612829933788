import "core-js/modules/es.array.push.js";
import { getAliYunOSSToken } from '@/api/common';
import { client, getFileNameUUID } from '@/utils/aliyun.oss';
import { imageURL } from '@/config';
import { gp } from '@gp';
const baseSize = 1024;
const contentType = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
};
export default function useUpload() {
  const uploadConfig = reactive({
    loading: false,
    headers: {},
    maxImageSize: baseSize * baseSize * 10,
    maxVideoSize: baseSize * baseSize * 100,
    maxAudioSize: baseSize * baseSize * 20,
    maxExcelSize: baseSize * baseSize * 100,
    maxWordSize: baseSize * baseSize * 500,
    maxTxtSize: baseSize * baseSize * 20,
    maxCertificateSize: baseSize * baseSize * 1,
    maxOtherSize: baseSize * baseSize * 100,
    excelFormat: ['xls', 'xlsx'],
    wordFormat: ['doc', 'docx'],
    imageFormat: ['jpg', 'jpeg', 'png', 'gif'],
    audioFormat: ['mp3', 'ogg', 'wav'],
    videoFormat: ['mp4'],
    txtFormat: ['txt'],
    certificateFormat: ['p12'],
    queue: {},
    count: {
      upload: 0,
      finish: 0,
      list: []
    }
  });
  const credentialsData = reactive({
    loading: false,
    createTime: '',
    data: null,
    expire: 14 * 60 * 1000
  });
  const bytesToSize = limit => {
    let size = '';
    if (limit < 0.1 * baseSize) {
      size = `${limit.toFixed(2)}B`;
    } else if (limit < 0.1 * baseSize * baseSize) {
      size = `${(limit / baseSize).toFixed(2)}KB`;
    } else if (limit < 0.1 * baseSize * baseSize * baseSize) {
      size = `${(limit / (baseSize * baseSize)).toFixed(2)}MB`;
    } else {
      size = `${(limit / (baseSize * baseSize * baseSize)).toFixed(2)}GB`;
    }
    let sizeStr = `${size}`;
    let index = sizeStr.indexOf('.');
    let dou = sizeStr.substr(index + 1, 2);
    if (dou === '00') {
      return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
    }
    return size;
  };
  const getUrlName = url => {
    let tempName = '';
    if (url) {
      let urlArr = url.split('?');
      if (urlArr[0]) {
        let appU = urlArr[0].split('/');
        tempName = appU[appU.length - 1];
      }
    }
    return tempName;
  };
  const getStorageAliYunOSSToken = () => {
    try {
      let tempToken = sessionStorage.getItem('AliYunOSSToken');
      if (tempToken) {
        let tempOss = JSON.parse(tempToken);
        credentialsData.data = tempOss.data;
        credentialsData.createTime = tempOss.createTime;
      }
    } catch (e) {
      credentialsData.data = null;
      credentialsData.createTime = '';
    }
  };
  const handleGlobalBeforeUpload = file => {
    uploadConfig.count.upload += 1;
    let fileSize = file.size;
    let tempFormat = file.name.split('.').pop().toLowerCase();
    let tempMaxSize = 0;
    if (uploadConfig.imageFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxImageSize;
    } else if (uploadConfig.audioFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxAudioSize;
    } else if (uploadConfig.videoFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxVideoSize;
    } else if (uploadConfig.excelFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxExcelSize;
    } else if (uploadConfig.wordFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxWordSize;
    } else if (uploadConfig.txtFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxTxtSize;
    } else if (uploadConfig.certificateFormat.includes(tempFormat)) {
      tempMaxSize = uploadConfig.maxCertificateSize;
    } else {
      tempMaxSize = uploadConfig.maxOtherSize;
    }
    if (fileSize > tempMaxSize) {
      gp.$baseMessage(`文件不能超过${bytesToSize(tempMaxSize)}.`, 'error', 'vab-hey-message-error', false);
      return false;
    }
    if (credentialsData.data && new Date().getTime() - credentialsData.createTime < credentialsData.expire) {
      return true;
    }
    const getCredentialsFun = () => {
      return new Promise((resolve, reject) => {
        const tempTimer = () => {
          setTimeout(() => {
            if (credentialsData.loading) {
              tempTimer();
            } else {
              setTimeout(() => {
                if (credentialsData.data && new Date().getTime() - credentialsData.createTime < credentialsData.expire) {
                  resolve(true);
                } else {
                  uploadConfig.count.upload -= 1;
                  reject(false);
                }
              }, 150);
            }
          }, 150);
        };
        tempTimer();
      });
    };
    if (credentialsData.loading) {
      return getCredentialsFun();
    } else {
      credentialsData.loading = true;
      return new Promise((resolve, reject) => {
        getAliYunOSSToken().then(res => {
          credentialsData.loading = false;
          if (res.data) {
            credentialsData.data = res.data;
            credentialsData.createTime = new Date().getTime();
            sessionStorage.setItem('AliYunOSSToken', JSON.stringify({
              data: credentialsData.data,
              createTime: credentialsData.createTime
            }));
            resolve(true);
          } else {
            credentialsData.data = null;
            credentialsData.createTime = '';
            uploadConfig.count.upload -= 1;
            reject(false);
          }
        }).catch(() => {
          credentialsData.loading = false;
          credentialsData.data = null;
          credentialsData.createTime = '';
          uploadConfig.count.upload -= 1;
          reject(false);
        });
      });
    }
  };
  const handleUpload = async config => {
    const tempClient = client({
      region: credentialsData.data.region,
      accessKeyId: credentialsData.data.AccessKeyId,
      accessKeySecret: credentialsData.data.AccessKeySecret,
      stsToken: credentialsData.data.SecurityToken,
      bucket: credentialsData.data.bucket
    });
    const putUpload = () => {
      return new Promise(resolve => {
        let fileName = '';
        let temporary = config.file.name.lastIndexOf('.');
        let fileNameLength = config.file.name.length;
        let fileFormat = config.file.name.substring(temporary + 1, fileNameLength);
        if (config.data.rename) {
          // 文件随机命名
          fileName = `${getFileNameUUID()}.${fileFormat}`;
        } else {
          // 保持原来文件名，多加一级目录防止重名
          fileName = `${getFileNameUUID()}/${config.file.name}`;
        }
        let tempTime = new Date().getTime();
        uploadConfig.queue[fileName] = true;
        let headers = {};
        if (contentType[fileFormat]) {
          headers['Content-Type'] = contentType[fileFormat];
        }
        tempClient.put(`/tender/${config.data && config.data.folder ? `${config.data.folder}/` : ``}${fileName}`, config.file, {
          headers
        }).then(result => {
          delete uploadConfig.queue[fileName];
          config.file.url = `${imageURL}/${result.name}`;
          resolve({
            url: `${imageURL}/${result.name}`,
            time: tempTime,
            fileFormat: fileFormat,
            originName: config.file.name.substring(0, config.file.name.lastIndexOf('.')),
            result: 'SUCCESS'
          });
        }).catch(error => {
          delete uploadConfig.queue[fileName];
          resolve({
            result: 'FAIL',
            error: error
          });
        });
      });
    };
    const data = await putUpload();
    uploadConfig.count.finish += 1;
    if (data.result === 'SUCCESS') {
      uploadConfig.count.list.push({
        url: data.url,
        time: data.time,
        fileFormat: data.fileFormat,
        originName: data.originName
      });
    } else {
      uploadConfig.count.list.push(null);
      config.onError(data.error);
    }
    if (uploadConfig.count.upload === uploadConfig.count.finish) {
      const compare = property => {
        return function (a, b) {
          let value1 = a[property];
          let value2 = b[property];
          let _a = value1.match(/^(.*?)(\d*)$/);
          let _b = value2.match(/^(.*?)(\d*)$/);
          if (_a[1] === _b[1]) {
            return parseInt(_a[2] || 0) - parseInt(_b[2] || 0);
          } else {
            return _a[1].localeCompare(_b[1]);
          }
        };
      };
      uploadConfig.count.list.sort(compare('originName'));
      let tempArray = [];
      for (let i in uploadConfig.count.list) {
        if (uploadConfig.count.list[i]) {
          if (config.data && config.data.valueFormat === 'object') {
            tempArray.push(uploadConfig.count.list[i]);
          } else {
            tempArray.push(uploadConfig.count.list[i].url);
          }
        } else {
          tempArray.push(null);
        }
      }
      config.onSuccess({
        data: tempArray
      });
      uploadConfig.count.upload = 0;
      uploadConfig.count.finish = 0;
      uploadConfig.count.list = [];
    }
  };
  const handleGlobalUploadError = () => {};
  const getFileType = url => {
    let tempType = '';
    if (/.jpg/i.test(url) || /.jpeg/i.test(url) || /.png/i.test(url) || /.gif/i.test(url)) {
      tempType = 'image';
    } else if (/.mp4/i.test(url)) {
      tempType = 'video';
    } else if (/.mp3/i.test(url) || /.ogg/i.test(url) || /.wav/i.test(url)) {
      tempType = 'audio';
    } else if (/.pdf/i.test(url)) {
      tempType = 'pdf';
    } else if (/.xls/i.test(url) || /.xlsx/i.test(url) || /.csv/i.test(url)) {
      tempType = 'excel';
    } else if (/.doc/i.test(url) || /.docx/i.test(url)) {
      tempType = 'word';
    } else if (/.ppt/i.test(url) || /.pptx/i.test(url)) {
      tempType = 'ppt';
    } else if (/.p12/i.test(url)) {
      tempType = 'certificate';
    } else {
      tempType = 'other';
    }
    return tempType;
  };
  getStorageAliYunOSSToken();
  watchEffect(() => {
    if (uploadConfig.queue && Object.keys(uploadConfig.queue).length > 0) {
      uploadConfig.loading = true;
    } else {
      uploadConfig.loading = false;
    }
  });
  return {
    uploadConfig,
    credentialsData,
    getUrlName,
    getFileType,
    getStorageAliYunOSSToken,
    handleGlobalBeforeUpload,
    handleUpload,
    handleGlobalUploadError
  };
}