import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { __read, __spreadArray } from "tslib";
import { defineComponent as _defineComponent } from 'vue';
import { useRoutesStore } from '@/store/modules/routes';
import { useSettingsStore } from '@/store/modules/settings';
import variables from '@vab/styles/variables/variables.module.scss';
import { defaultOpeneds, uniqueOpened } from '@/config';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var props = __props;
    var settingsStore = useSettingsStore();
    var collapse = storeToRefs(settingsStore).collapse;
    var routesStore = useRoutesStore();
    var _b = storeToRefs(routesStore),
      routes = _b.getRoutes,
      activeMenu = _b.getActiveMenu,
      partialRoutes = _b.getPartialRoutes;
    var handleRoutes = computed(function () {
      return props.layout === 'comprehensive' ? partialRoutes.value : routes.value.flatMap(function (route) {
        return route.meta.levelHidden && route.children ? __spreadArray([], __read(route.children), false) : route;
      });
    });
    var __returned__ = {
      props: props,
      settingsStore: settingsStore,
      collapse: collapse,
      routesStore: routesStore,
      routes: routes,
      activeMenu: activeMenu,
      partialRoutes: partialRoutes,
      handleRoutes: handleRoutes,
      get variables() {
        return variables;
      },
      get defaultOpeneds() {
        return defaultOpeneds;
      },
      get uniqueOpened() {
        return uniqueOpened;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});