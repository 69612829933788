import { defineComponent as _defineComponent } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'VabSubMenu',
  props: {
    itemOrMenu: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var __returned__ = {};
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});