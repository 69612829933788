import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import { defineComponent as _defineComponent } from 'vue';
import { useSettingsStore } from '@/store/modules/settings';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup: function (__props, _a) {
    var __expose = _a.expose;
    __expose();
    var $pub = inject('$pub');
    var settingsStore = useSettingsStore();
    var theme = storeToRefs(settingsStore).theme;
    var refreshRoute = function () {
      $pub('reload-router-view');
    };
    var __returned__ = {
      $pub: $pub,
      settingsStore: settingsStore,
      theme: theme,
      refreshRoute: refreshRoute
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});