/**
 * 格式化权限配置数据
 * @param config
 */
export const formatAccessConfig = (config: any[]) => {
  let tempArray: any[] = []
  const caleConfig = (data: any, tree: any[] = [], billId?: number) => {
    for (let i in data) {
      let tempRecord = data[i]
      let tempAccessArray = []
      try {
        tempAccessArray = JSON.parse(tempRecord.accessList) || []
      } catch (e) {
        tempAccessArray = []
      }
      if (tempAccessArray.length > 0) {
        tempRecord.children = tempAccessArray
      }
      let tempKey = `${tempRecord.suffix
        .substring(0, 1)
        .toUpperCase()}${tempRecord.suffix.substring(1)}`
      if (tree.length > 0) {
        tempKey = `${tree[tree.length - 1]}${tempKey}`
      }
      let tempAccess: any = {
        billId: billId,
        key: tempKey,
        name: data[i].name,
        tree: [...tree, tempKey],
      }
      if (data[i].mark) {
        tempAccess.mark = data[i].mark
        tempArray.push(tempAccess)
      }
      if (data[i].children) {
        caleConfig(data[i].children, tempAccess.tree, data[i].billId)
      }
    }
  }
  caleConfig(config, [])
  return tempArray
}

/**
 * 判断是否为空
 * @param data
 */
export const isEmpty = (data: any) => {
  return data === '' || data === undefined || data === null
}

/**
 * 判断是否是图片
 */
export const isImage = (url: any) => {
  if (
    url &&
    (/.jpg/i.test(url) ||
      /.jpeg/i.test(url) ||
      /.png/i.test(url) ||
      /.gif/i.test(url))
  ) {
    return true
  }
  return false
}

/**
 * 根据数字获取位集合
 * @param num
 */
export const getBitArray = (num: number) => {
  let array = []
  for (let i = 0; i < num / 2; i++) {
    let a = 1 << i
    if (a & num) {
      array.push(a)
    }
  }
  return array
}

/**
 * 获取树形数组中指定节点
 * @param nodes
 * @param value
 * @param searchKey
 * @returns
 */
export const searchTree: any = (
  nodes: any[],
  value: string | number,
  searchKey: string
) => {
  searchKey = searchKey || 'id'
  for (let _i = 0; _i < nodes.length; _i++) {
    if (nodes[_i][searchKey] === value) {
      return nodes[_i]
    } else {
      if (nodes[_i].children && nodes[_i].children.length > 0) {
        let res = searchTree(nodes[_i].children, value, searchKey)
        if (res) {
          return res
        }
      }
    }
  }
  return null
}

/**
 * 获取随机数
 * @param minNum
 * @param maxNum
 * @returns {number}
 */
export const randomNum = (minNum = 0, maxNum = 10) => {
  return parseInt(`${Math.random() * (maxNum - minNum + 1) + minNum}`, 10)
}
