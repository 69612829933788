import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/setting',
    name: 'Setting',
    component: Layout,
    meta: {
      title: '设置',
      icon: 'settings-2-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'menu',
        name: 'SettingMenu',
        component: () => import('@/views/setting/menu/index.vue'),
        meta: {
          title: '菜单管理',
          access: true,
        },
      },
      {
        path: 'account',
        name: 'SettingAccount',
        component: () => import('@/views/setting/account/index.vue'),
        meta: {
          title: '账号管理',
          access: true,
        },
      },
      {
        path: 'role',
        name: 'SettingRole',
        component: () => import('@/views/setting/role/index.vue'),
        meta: {
          title: '角色管理',
          access: true,
        },
      },
      {
        path: 'department',
        name: 'SettingDepartment',
        component: () => import('@/views/setting/department/index.vue'),
        meta: {
          title: '部门管理',
          access: true,
        },
      },
      {
        path: 'post',
        name: 'SettingPost',
        component: () => import('@/views/setting/post/index.vue'),
        meta: {
          title: '岗位管理',
          access: true,
        },
      },
      {
        path: 'config',
        name: 'SettingConfig',
        component: () => import('@/views/setting/config/index.vue'),
        meta: {
          title: '站点配置',
          access: true,
        },
      },
      {
        path: 'plugin',
        name: 'SettingPlugin',
        component: Layout,
        meta: {
          title: 'word插件配置',
          access: true,
        },
        children: [
          {
            path: 'general',
            name: 'SettingPluginGeneral',
            component: () => import('@/views/setting/plugin/general/index.vue'),
            meta: {
              title: '通用配置',
              access: true,
            },
          },
          {
            path: 'menu',
            name: 'SettingPluginMenu',
            component: () => import('@/views/setting/plugin/menu/index.vue'),
            meta: {
              title: '菜单配置',
              access: true,
            },
          },
        ],
      },
      {
        path: 'pageHeaderFooter',
        name: 'SettingPageHeaderFooter',
        component: () => import('@/views/setting/pageHeaderFooter/index.vue'),
        meta: {
          title: '页眉页脚',
          access: true,
        },
      },
      {
        path: 'watermark',
        name: 'SettingWatermark',
        component: () => import('@/views/setting/watermark/index.vue'),
        meta: {
          title: '水印',
          access: true,
        },
      },
      {
        path: 'log',
        name: 'SettingLog',
        component: () => import('@/views/setting/log/index.vue'),
        meta: {
          title: '日志管理',
          access: true,
        },
      },
    ],
  },
]
