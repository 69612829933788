import Layout from '@vab/layouts/index.vue'
export default [
  {
    path: '/material',
    name: 'Material',
    component: Layout,
    meta: {
      title: '素材',
      icon: 'book-mark-line',
      breadcrumbHidden: true,
      access: true,
    },
    children: [
      {
        path: 'index',
        name: 'MaterialIndex',
        component: () => import('@/views/material/index.vue'),
        meta: {
          title: '图片素材',
          access: true,
        },
      },
    ],
  },
]
