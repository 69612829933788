import { defineComponent, onMounted, onUnmounted } from 'vue';
import zhLocale from 'element-plus/es/locale/lang/zh-cn';
// import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import { useSettingsStore } from '@/store/modules/settings';
export default defineComponent({
  setup() {
    const {
      setWebview
    } = useSettingsStore();
    let webviewTimer = null;
    const updateWebview = () => {
      if (window.chrome && window.chrome.webview) {
        setWebview(true);
      } else {
        setWebview(false);
      }
    };
    onMounted(() => {
      updateWebview();
      webviewTimer = setInterval(() => {
        updateWebview();
      }, 5000);
    });
    onUnmounted(() => {
      clearTimeout(webviewTimer);
    });
    return {
      zhLocale,
      updateWebview
    };
  }
});