import type { DirectiveBinding } from 'vue'
function unit(value: string | number) {
  return String(value).endsWith('%') ? '' : 'px'
}

const style: any = {
  display: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.display = binding.value
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.display = binding.value
      }
    },
    unmounted(el: any) {
      el.style.display = null
    },
  },
  width: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.width = binding.value + unit(binding.value)
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.width = binding.value + unit(binding.value)
      }
    },
    unmounted(el: any) {
      el.style.width = null
    },
  },
  height: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.height = binding.value + unit(binding.value)
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.height = binding.value + unit(binding.value)
      }
    },
    unmounted(el: any) {
      el.style.height = null
    },
  },
  margin: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.margin = binding.value + unit(binding.value)
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.margin = binding.value + unit(binding.value)
      }
    },
    unmounted(el: any) {
      el.style.margin = null
    },
  },
  padding: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.padding = binding.value + unit(binding.value)
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.padding = binding.value + unit(binding.value)
      }
    },
    unmounted(el: any) {
      el.style.padding = null
    },
  },
  font: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding && binding.value) {
        el.style.fontSize = `${binding.value}px`
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding && binding.value) {
        el.style.fontSize = `${binding.value}px`
      }
    },
    unmounted(el: any) {
      el.style.fontSize = null
    },
  },
  color: {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.color = binding.value
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.color = binding.value
      }
    },
    unmounted(el: any) {
      el.style.color = null
    },
  },
  'bg-color': {
    mounted(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.backgroundColor = binding.value
      }
    },
    update(el: any, binding: DirectiveBinding) {
      if (binding.value) {
        el.style.backgroundColor = binding.value
      }
    },
    unmounted(el: any) {
      el.style.backgroundColor = null
    },
  },
}

export default style
