import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "avatar-dropdown"
};
const _hoisted_2 = {
  class: "user-name"
};
const _hoisted_3 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_Personal = _resolveComponent("Personal");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dropdown, {
    onCommand: _ctx.handleCommand,
    onVisibleChange: _ctx.handleVisibleChange
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        command: "personalCenter"
      }, {
        default: _withCtx(() => [_createVNode(_component_vab_icon, {
          icon: "user-line"
        }), _cache[0] || (_cache[0] = _createElementVNode("span", null, "个人中心", -1 /* HOISTED */))]),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_dropdown_item, {
        command: "logout"
      }, {
        default: _withCtx(() => [_createVNode(_component_vab_icon, {
          icon: "logout-circle-r-line"
        }), _cache[1] || (_cache[1] = _createElementVNode("span", null, "退出登录", -1 /* HOISTED */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_avatar, {
      class: "user-avatar",
      src: _ctx.avatar
    }, null, 8 /* PROPS */, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
      class: "hidden-xs-only",
      title: _ctx.username
    }, _toDisplayString(_ctx.name || _ctx.username), 9 /* TEXT, PROPS */, _hoisted_3), _createVNode(_component_vab_icon, {
      class: _normalizeClass(["vab-dropdown", {
        'vab-dropdown-active': _ctx.active
      }]),
      icon: "arrow-down-s-line"
    }, null, 8 /* PROPS */, ["class"])])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onCommand", "onVisibleChange"]), _createVNode(_component_Personal, {
    ref: "personalRef",
    onOnSubmit: _ctx.refresh
  }, null, 8 /* PROPS */, ["onOnSubmit"])], 64 /* STABLE_FRAGMENT */);
}