import request from '@/utils/request'

/**
 * 获取菜单列表
 * @param data
 * @returns {*}
 */
export function getMenu() {
  return request({
    url: '/api/admin/menu/list',
    method: 'get',
  })
}

/**
 * 删除菜单
 * @param id
 * @returns {*}
 */
export function deleteMenu(id: string | number) {
  return request({
    url: '/api/admin/menu/delete',
    method: 'post',
    data: { id },
  })
}

/**
 * 获取菜单详情
 * @param id
 * @returns {*}
 */
export function getMenuDetail(id: string | number) {
  return request({
    url: '/api/admin/menu/detail',
    method: 'get',
    params: { id },
  })
}

/**
 * 添加菜单
 * @param data
 * @returns {*}
 */
export function addMenu(data: any) {
  return request({
    url: '/api/admin/menu/add',
    method: 'post',
    data: data,
  })
}

/**
 * 更新菜单
 * @param data
 * @returns {*}
 */
export function updateMenu(data: any) {
  return request({
    url: '/api/admin/menu/update',
    method: 'post',
    data: data,
  })
}
