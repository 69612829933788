import { defineComponent, computed, h } from 'vue';
import { hasAccess } from '@/utils/permission';
export default defineComponent({
  name: 'Access',
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props) {
    const isPermission = computed(() => {
      return hasAccess(props.name);
    });
    return {
      isPermission
    };
  },
  render() {
    return this.isPermission ? h(this.$slots.default) : null;
  }
});